<template>
  <div class="login">
    <v-container fill-height>
      <v-row align="center">
        <v-card max-width="400px" class="mx-auto pa-8">
          <v-form @submit.prevent="login({ email: email, password: password })">
            <v-img
              class="mx-auto mb-4"
              width="94"
              src="@/assets/mini_logo_blue.png"
            ></v-img>
            <h4 class="mb-10 text-center">Bienvenido a Discursa</h4>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Email"
                  v-model="email"
                  dense
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Contraseña"
                  v-model="password"
                  dense
                  prepend-inner-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <transition name="shake">
                <v-col v-if="feedback" cols="12" class="red--text mt-n8 shake">
                  {{ feedback }}
                </v-col>
              </transition>

              <v-col>
                <v-btn type="submit" class="success">Ingresar</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      email: "",
      password: "",
      animated: false,
      showPassword: false,
    };
  },

  methods: {
    ...mapActions(["login"]),
    /*  login(email, password) {
      this.feedback = "";

        
    }, */
  },
  computed: {
    ...mapState(["feedback"]),
    animate() {
      if (this.feedback) {
        return true;
      } else return false;
    },
  },
};
</script>

<style>
.login {
  height: 100vh;
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
